import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MLS from "../../assets/MLS.png";
import NoImage from "../../assets/No-image-available.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Swal from "sweetalert2";
import {
  faEnvelope,
  faMapMarkerAlt,
  faCheckCircle,
  faTimesCircle,
  faPlay,
  faList,
  faClipboardList,
  faCalendarCheck,
  faFileArrowUp,
  faComments,
  faClipboardCheck,
  faPhoneVolume,
  faLock,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  FormGroup,
  TextareaAutosize,
  Button,
} from "@material-ui/core";
import { getUserAliasIdFromEmail, postPriorityApi } from "../Api/betaApi";
import apiModule from "../Api/apiModule";
import Footer from "../Footer/footer";
import "../Property-details/property-details.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Container,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import "react-tabs/style/react-tabs.css";
import { Amplify, Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { GoogleLogin } from "react-google-login";

const PropertyDetails = () => {
  const { mls, descriptor } = useParams();
  const [propertyData, setPropertyData] = useState(null);
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "+1",
    message: "",
    subject: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [offerValues, setOfferValues] = React.useState({
    password: "",
    email: "",
  });
  const [showPopup, setShowPopup] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const userJson = localStorage.getItem("userJson");

  // Parse the userJson if it exists
  const user = userJson ? JSON.parse(userJson) : null;

  // Access attributes from the user object
  const logo = user ? user.logo : null;
  const tenant_name = user?.tenant_name;
  const userDashboard = user ? user.user_auth_urls : null;

  const userInfo = localStorage.getItem("userInfo");
  const userAliasID = localStorage.getItem("userAliasID");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const api = await apiModule;

        const response = await api.getPropertyByMLS(mls, descriptor);
        console.log(response);
        setPropertyData(response);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    fetchData();
  }, [mls, descriptor]);

  if (user) {
    Amplify.configure({
      Auth: user.cognito,
    });
  }

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setOfferValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const requestTypes = [
    {
      title: "Start an Offer",
      description: "Submit an offer made by the user",
      request_type: "MAKE_AN_OFFER",
      icon: faPlay,
    },
    {
      title: "List Property",
      description: "List a property",
      request_type: "LIST_PROPERTY",
      icon: faList,
    },
    {
      title: "Schedule Inspection",
      description: "Schedule a property inspection",
      request_type: "SCHEDULE_PROPERTY_INSPECTION",
      icon: faClipboardList,
    },
    {
      title: "Schedule Showing",
      description: "Schedule a view of the property",
      request_type: "SCHEDULE_PROPERTY_VIEW",
      icon: faCalendarCheck,
    },
    {
      title: "Request Property Valuation",
      description: "Quickly list a property",
      request_type: "REQUEST_PROPERTY_VALUE",
      icon: faFileArrowUp,
    },
    {
      title: "Q & A",
      description: "Ask questions related to the property",
      request_type: "QA",
      icon: faComments,
    },
    {
      title: "Upload Documents",
      description: "Upload documents for user/agent",
      request_type: "DOCUMENTS",
      icon: faFileArrowUp,
    },
    {
      title: "Request Disclosure",
      description: "Request a disclosure",
      request_type: "REQUEST_DISCLOSURE",
      icon: faClipboardCheck,
    },
    {
      title: "Contact us",
      description: "Contact us",
      request_type: "CONTACT",
      icon: faPhoneVolume,
    },
  ];

  const signIn = (user) => {
    console.log(Auth);
    return Auth.signIn(user.email, user.password);
  };

  const get_alias_id = async (email) => {
    const api = await apiModule;

    const response = await api.getUserAliasIdFromEmail(email);
    localStorage.setItem("userAliasID", response.UniqueAliasId);

    localStorage.setItem("userInfo", email);
  };

  const postCrossDomainMessage = (link, portal, data, IFrameId, login_type) => {
    let postURL;
    let iframeId;
    console.log(portal);
    console.log(link);
    console.log(data);
    console.log(IFrameId);
    console.log(login_type);

    if (portal) {
      postURL = "https://" + user?._auth_urls;
      iframeId = IFrameId;
    }
    const iframe = document.getElementById(iframeId);
    console.log(iframe);
    if (iframe == null) {
      return;
    }
    const iWindow = iframe.contentWindow;
    const storageData = { ...data, login_type: login_type };
    console.log(iWindow);
    console.log(storageData);
    setTimeout(function () {
      iWindow.postMessage(storageData, link);
      console.log("data sent");
    }, 10000);
  };

  const postUserData = (url, portal, iframeId) => {
    // Implement your postUserData function here or replace it with the actual implementation
    var credential = {
      email: offerValues.email,
      password: offerValues.password,
    };
    console.log(url);
    console.log(portal);
    console.log(iframeId);
    console.log(credential);
    console.log("iframe id ", iframeId);
    postCrossDomainMessage(url, portal, credential, iframeId, false);
  };

  const getRequestTypeURL = (request) => {
    const userInfo = localStorage.getItem("userInfo");
    const userAliasID = localStorage.getItem("userAliasID");
    var url;

    // If the request type is "Documents," return the custom URL
    if (request.request_type === "DOCUMENTS") {
      url = `https://${userDashboard}/#/main-forms/all-documents?property_id=${mls}_${descriptor}&property_address=${propertyData[0]?.address?.google_address}&unit_number=${propertyData[0]?.address?.unit_number}&ledger_item_details.city=${propertyData[0]?.address?.city}&ledger_item_details.state=${propertyData[0]?.address?.state}&ledger_item_details.zipcode=${propertyData[0]?.address?.zip_code}&request_type=${request.request_type}&user_id_alias=${userAliasID}&user_alias_email=${userInfo}`;
    } else if (request.request_type === "CONTACT") {
      url = `/#/landing/tenant/${tenant_name}/contacts`;
    } else {
      // For other request types, use the default URL with query parameter
      url = `https://${userDashboard}/#/main-forms/dynamic-forms?property_id=${mls}_${descriptor}&property_address=${propertyData[0]?.address?.google_address}&unit_number=${propertyData[0]?.address?.unit_number}&ledger_item_details.city=${propertyData[0]?.address?.city}&ledger_item_details.state=${propertyData[0]?.address?.state}&ledger_item_details.zipcode=${propertyData[0]?.address?.zip_code}&request_type=${request.request_type}&user_id_alias=${userAliasID}&user_alias_email=${userInfo}`;
    }
    return url;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log(offerValues.email);
    console.log(offerValues.password);
    let user_details = {
      email: offerValues.email,
      password: offerValues.password,
      showPassword: false,
      code: "",
      name: "",
    };

    try {
      await signIn(user_details);
      await get_alias_id(user_details.email);
      if (user?.user_auth_urls) {
        const iframe1 = document.createElement("iframe");
        iframe1.id = "user-ifr";
        iframe1.style.display = "none";
        iframe1.src = "https://" + user.user_auth_urls;

        document.body.appendChild(iframe1);
        postUserData(
          "https://" + user.user_auth_urls,
          "user_dashboard_default_metricrealties_template",
          iframe1.id
        );
      }
      Swal.fire({
        title: "Login Successfully",
        icon: "success",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) {
          // Navigate to the specified URL
          window.location.href = `/#/landing/tenant/${tenant_name}/home`;
        }
      });
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "User Not Found",
        text: "Please enter valid credentials!!",
        icon: "error",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) {
          // Navigate to the specified URL
          window.location.href = `/#/landing/tenant/${tenant_name}/property/${mls}/${descriptor}`;
          setShowPopup(true);
        }
      });
    }
  };

  async function signInWithGoogle(authObject) {
    // Configure Amplify with the given authObject
    Amplify.configure({
      Auth: authObject,
    });

    try {
      // Federated sign-in with Google
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (error) {
      console.log("Error signing in with Google:", error);
      throw error; // Rethrow the error to handle it in the calling function if needed.
    }
  }

  async function signInWith() {
    let location = window.location;
    let authObject = user.cognito;

    if (location.hostname !== "localhost") {
      let redirectSignIn = location.origin;
      let redirectSignOut = location.origin;
      authObject.oauth.redirectSignIn = redirectSignIn;
      authObject.oauth.redirectSignOut = redirectSignOut;
    } else {
      authObject.oauth.redirectSignIn = "http://localhost:3000";
      authObject.oauth.redirectSignOut = "http://localhost:3000";
    }
    console.log("authObject --- ", authObject);

    try {
      await signInWithGoogle(authObject);
      // Successful sign-in handling (e.g., redirect or any other logic)
    } catch (error) {
      console.log(error);
      this.alert.showErrorAlertWithMessage("Unable to Login");
    }
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleModalClose = () => {
    setShowPopup(false);
  };

  const showAlert = () => {
    Swal.fire({
      title: "Success",
      text: "Hey! We got your details, Will get back to you immediately",
      icon: "success",
      confirmButtonText: "OK",
      preConfirm: () => {
        // Refresh the page
        window.location.reload();
      },
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContactForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const contactFunction = async (e) => {
    e.preventDefault();

    // const url = `https://ch30e1uez8.execute-api.us-east-1.amazonaws.com/prod/Iam/tenant/${tenant_name}/Alias/user_email/${contactForm.email}`;
    try {
      const api = await apiModule;

      const response = await api.getUserAliasIdFromEmail(contactForm.email);

      // const response = await axios.get(url, {
      //   headers: requiredHeaders,
      // })
      // console.log(response.data);

      let prop_id = makeManualAddressData(
        e.target.elements.property_address?.value
      );
      console.log(prop_id);
      let payload = {
        ...contactForm,
        user_id_alias: response.UniqueAliasId,
        user_alias_email: e.target.elements.email?.value,
        request_type: "QA",
        property_id: tenant_name,
        property_address: "ALL",
        status_type: "active",
        tenant: tenant_name,
        DataAliasName: "test",
        DataAliasType: "private",
        parent_id: "test_parent",
        assigned_to: "test_alias",
        assignee_type: "test_user",
      };

      // const postUrl = `https://lsbm0an8i1.execute-api.us-east-1.amazonaws.com/prod/neuronService/priority`;
      // const postResponse = await axios.post(postUrl, payload, {
      //   headers: requiredHeaders,
      // })
      const postResponse = await api.postPriorityApi(payload);

      showAlert();

      console.log(postResponse);

      // return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const makeManualAddressData = (defaultPropertyAddress, defaultUnitNumber) => {
    let address = {
      property_address: defaultPropertyAddress,

      unit: defaultUnitNumber,
    };

    var hashtable = btoa(unescape(encodeURIComponent(JSON.stringify(address))));

    return hashtable;
  };

  // Replace with the desired city name
  const state = "WA"; // Replace with the desired state code

  const mapUrl = `https://www.greatschools.org/widget/map?textColor=0066B8&borderColor=FFCC66&width=400&height=450&zoom=13&state=${encodeURIComponent(
    state
  )}`;
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <></>,
    nextArrow: <></>,
  };

  const handleButtonClick = (request, url) => {
    if (userInfo && userAliasID) {
      window.location.href = url;
    } else {
      setSelectedRequest(request); // Set the selected request
      setShowPopup(true);
    }
  };

  return (
    <div>
      <div className="header-section">
        <p>
          <a className="para" href="mailto:info@webmail.com">
            <FontAwesomeIcon icon={faEnvelope} />
            info@webmail.com
          </a>
        </p>
        <p>
          <a
            className="para"
            href="https://www.google.com/maps?q=40+Lake+Bellevue+Suite+100"
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            Lake Bellevue Suite 100
          </a>
        </p>
      </div>
      <div className="property-header">
        <div className="logo">
          <img className="logo-home" src={logo} alt="Logo" />
        </div>
        {/* <div className="menu">{}</div> */}
      </div>

      {propertyData && (
        <div className="property-details">
          <Slider {...settings}>
            {propertyData && propertyData.length > 0 ? (
              propertyData[0]?.image_url &&
              propertyData[0]?.image_url.length > 0 ? (
                propertyData[0].image_url.map((imageUrl, index) => (
                  <div key={index} className="image-wrapper">
                    <img
                      src={imageUrl}
                      alt={`Property Image ${index + 1}`}
                      className="responsive-image"
                    />
                  </div>
                ))
              ) : (
                <img
                  src={NoImage}
                  alt="No images available"
                  className="responsive-image"
                />
              )
            ) : (
              <p>No property data available</p>
            )}
          </Slider>

          <div className="property-info">
            <div className="first-line">
              <p className="paras">
                {propertyData[0]?.bedroom.count || "N/A"} BEDS
              </p>
              <p className="paras">
                {propertyData[0]?.bathroom.count || "N/A"} BATHS
              </p>
              <p className="paras"> {propertyData[0]?.area?.lot} SQFT</p>
            </div>
            <h1 class="address">{propertyData[0]?.address?.google_address}</h1>
            <h1 class="price">${propertyData[0]?.price?.current}</h1>

            <h1 class="description-heading">Description</h1>
            <p class="description">{propertyData[0]?.description}</p>

            <div className="courtesy-info">
              <p class="description">
                * Listing provided courtesy of
                <img style={{ width: "5rem" }} src={MLS} alt="Image" /> as
                distributed by MLSGRID.
              </p>

              <p class="description">
                The information contained in this listing has not been verified
                by ShopProp or the MLS and should be verified by the buyer.
              </p>
            </div>

            <h1 class="description-heading">Property Details</h1>
            <div className="background-property">
              <p className="property-detail">
                <span className="property-id">Property Id :</span>
                <span className="property-value">
                  {propertyData[0]?.internal_unique_id}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">MLS# :</span>
                <span className="property-value">
                  {propertyData[0]?.internal_unique_id.substring(
                    propertyData[0]?.internal_unique_id.length - 7
                  )}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Source :</span>
                <span className="property-value">
                  {propertyData[0]?.property_mls_name}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Full Address :</span>
                <span className="property-value">
                  {propertyData[0]?.address?.full_address}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Price :</span>
                <span className="property-value">
                  ${propertyData[0]?.price?.current}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">HOA Fees :</span>
                <span className="property-value">
                  ${propertyData[0]?.hoa?.per_month}/month
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Lot Area :</span>
                <span className="property-value">
                  {propertyData[0]?.area.lot} SQFT
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Finished Area :</span>
                <span className="property-value">
                  {propertyData[0]?.area.finished} SQFT
                </span>
              </p>

              <br />

              <p className="property-detail">
                <span className="property-id">Bedrooms :</span>
                <span className="property-value">
                  {propertyData[0]?.bedroom.count || "N/A"}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Bathrooms :</span>
                <span className="property-value">
                  {propertyData[0]?.bathroom.count || "N/A"}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Year Built :</span>
                <span className="property-value">
                  {propertyData[0]?.year_built}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Car Parking :</span>
                <span className="property-value">
                  {propertyData[0]?.parking?.count_car || "-"}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Bike Parking :</span>
                <span className="property-value">
                  {propertyData[0]?.parking?.count_bike || "-"}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Stories :</span>
                <span className="property-value">
                  {propertyData[0]?.stories}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Floor :</span>
                <span className="property-value">
                  {propertyData[0]?.floor || "-"}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Listing Agent :</span>
                <span className="property-value">
                  {propertyData[0]?.listing_agent?.fname}
                </span>
              </p>

              <p className="property-detail">
                <span className="property-id">Listing Company :</span>
                <span className="property-value">
                  {propertyData[0]?.listing_agent?.listing_company_name}
                </span>
              </p>
            </div>
            <h1 class="description-heading">Request Types</h1>
            <div className="request-types">
              {requestTypes.map((request) => (
                <div
                  className="request-type text-center"
                  key={request.request_type}
                >
                  <button
                    className="btn-request d-flex"
                    onClick={() =>
                      handleButtonClick(request, getRequestTypeURL(request))
                    }
                  >
                    <FontAwesomeIcon
                      className="requets-icons"
                      icon={request.icon}
                    />
                    {request.title}
                  </button>
                  <Modal
                    isOpen={showPopup}
                    onRequestClose={handleModalClose}
                    contentLabel="Login Modal"
                    style={{
                      content: {
                        height: "fit-content",
                        top: "20%",
                      },
                    }}
                  >
                    <Container component="main" maxWidth="xs">
                      <div className="text-center">
                        <Typography component="h1" variant="h5">
                          Sign in
                        </Typography>
                        <p className="mt-2" style={{ fontSize: "70%" }}>
                          We're glad to see you here again.Please sign in
                        </p>
                        <form onSubmit={handleLogin} key={request.request_type}>
                          <FormGroup>
                            <TextField
                              label="Email"
                              name="email"
                              value={offerValues.email}
                              onChange={handleChange2}
                              required
                              type="email"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <TextField
                              label="Password"
                              name="password"
                              value={offerValues.password}
                              type={showPassword ? "text" : "password"}
                              onChange={handleChange2}
                              required
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faLock} />
                                  </InputAdornment>
                                ),
                                // Add the endAdornment for the password visibility toggle
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      onClick={handleTogglePasswordVisibility}
                                      onMouseDown={(e) => e.preventDefault()} // Prevent focus change when clicking the toggle button
                                    >
                                      {showPassword ? (
                                        <FontAwesomeIcon icon={faEyeSlash} />
                                      ) : (
                                        <FontAwesomeIcon icon={faEye} />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormGroup>
                          <Grid
                            item
                            xs
                            className="mb-2"
                            style={{ float: "right" }}
                          >
                            <Link
                              href={`https://${userDashboard}/#/forgot-password`}
                              variant="body2"
                            >
                              Forgot password?
                            </Link>
                          </Grid>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                          >
                            Sign In
                          </Button>
                          <Grid container>
                            <Grid item className="m-2">
                              <Link
                                href={`https://${userDashboard}/#/forgot-password`}
                                variant="body2"
                              >
                                {"Are you a new user? Sign Up"}
                              </Link>
                            </Grid>
                          </Grid>

                          {/* <Box mt={2}>
                            Or Sign in with :
                            <Button fullWidth onClick={signInWith}>
                              <span style={{ fontSize: "x-large" }}>
                                {" "}
                                <FontAwesomeIcon icon={faGoogle} />
                              </span>
                            </Button>
                          </Box> */}
                        </form>
                      </div>
                    </Container>
                  </Modal>
                </div>
              ))}
            </div>
            <br></br>
            <h1 class="description-heading">Amenities</h1>
            <div className="amenities-details">
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.pool.value ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">Swimming Pool</span>
              </p>
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.garage.closed ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">Closed Garage </span>
              </p>
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.heating.value ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">Central Heating </span>
              </p>
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.green_house.value ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">Green House </span>
              </p>
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.laundry.value ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">InUnit Laundry</span>
              </p>
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.basement.value ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">Basement </span>
              </p>
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.accessibilty.value ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">Accessable </span>
              </p>
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.cooling.value ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">With AC </span>
              </p>
            </div>
            <h1 class="description-heading">Additional Details</h1>
            <div className="additional-details">
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.has_view.value ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">Open House</span>
              </p>
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.price.extra.is_reduced ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">Price Reduced </span>
              </p>
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.short_sale.value ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">Short Sale </span>
              </p>
              <p style={{ display: "flex", marginBottom: "2rem" }}>
                {propertyData[0]?.fixer_upper ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="blue-icon" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="red-icon" />
                )}
                <span className="property-check-value">Fixer Upper </span>
              </p>
            </div>
            <Tabs>
              <TabList className="tab-list">
                <Tab className="tab" selectedClassName="selected-tab">
                  School Ratings
                </Tab>
                <Tab className="tab" selectedClassName="selected-tab">
                  Property Estimate
                </Tab>
                <Tab className="tab" selectedClassName="selected-tab">
                  More details
                </Tab>
              </TabList>

              <TabPanel className="tab-panel">
                <iframe
                  src={mapUrl}
                  width="100%"
                  height="450"
                  frameBorder="0"
                  style={{ border: "0" }}
                  allowFullScreen
                ></iframe>
              </TabPanel>

              <TabPanel>
                {/* Content for Second Tab */}
                <p class="description">
                  Below is a rough estimate of the value of the home. When
                  buying or selling a home we will provide you with a more exact
                  price determined by data analyzed by us.
                </p>

                <div className="avmwidget" id="widgetavm">
                  <form
                    onSubmit={contactFunction}
                    style={{
                      marginTop: "20px",
                      width: "98%",
                      marginBottom: "55px",
                    }}
                  >
                    <h2
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        background: "#333d79",
                        padding: "1rem",
                      }}
                    >
                      Fill the below form to get accurate Home value
                    </h2>
                    <FormGroup>
                      <TextField
                        label="Name"
                        name="name"
                        value={contactForm.name}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={contactForm.email}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControl variant="outlined">
                        <InputLabel id="country-code-label">
                          Country Code
                        </InputLabel>
                        <Select
                          labelId="country-code-label"
                          id="country-code-select"
                          name="countryCode"
                          value={contactForm.countryCode}
                          onChange={handleChange}
                          label="Country Code"
                        >
                          <MenuItem value="+1">+1 (United States)</MenuItem>
                          <MenuItem value="+44">+44 (United Kingdom)</MenuItem>
                          <MenuItem value="+91">+91 (India)</MenuItem>
                          <MenuItem value="+61">+61 (Australia)</MenuItem>
                          <MenuItem value="+81">+81 (Japan)</MenuItem>
                          <MenuItem value="+86">+86 (China)</MenuItem>
                          <MenuItem value="+49">+49 (Germany)</MenuItem>
                          <MenuItem value="+33">+33 (France)</MenuItem>
                          <MenuItem value="+55">+55 (Brazil)</MenuItem>
                          <MenuItem value="+27">+27 (South Africa)</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        label="Phone"
                        name="phone"
                        type="tel"
                        value={contactForm.phone}
                        onChange={handleChange}
                        required
                        style={{ marginLeft: "16px" }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        label="Subject"
                        multiline
                        rows={4}
                        value={contactForm.subject}
                        onChange={handleChange}
                        name="subject"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        label="Message"
                        multiline
                        rows={4}
                        value={contactForm.message}
                        onChange={handleChange}
                        name="message"
                        required
                      />
                    </FormGroup>
                    <div className="send-btn">
                      <button
                        className="w-75 mt-3 p-2 send-btn-2 btn"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                  <md-avm-widget
                    rvmAddress={propertyData[0]?.address.google_address}
                    rvmWidgetKey="Y8E341247-6C56-471E-9E34-DFA842598976" // Replace with your AVM widget key
                    rvmShowRprLinks="false"
                    rvmtesting="false"
                  ></md-avm-widget>
                </div>
              </TabPanel>

              <TabPanel>
                {/* Content for Third Tab */}
                <div className="background-property">
                  <h1 className="more-details">Area</h1>
                  <p>
                    <span className="property-id">Finished:</span>
                    <span className="property-value">
                      {propertyData[0]?.area.finished}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Lot Area :</span>
                    <span className="property-value">
                      {propertyData[0]?.area.lot}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Living Area Units :</span>
                    <span className="property-value">
                      {propertyData[0]?.area.extra?.LivingAreaUnits}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Lot Size Units :</span>
                    <span className="property-value">
                      {propertyData[0]?.area.extra.LotSizeUnits}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Building Area Units:</span>
                    <span className="property-value">
                      {propertyData[0]?.area.extra.BuildingAreaUnits}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      Square Footage Unfinished :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.area.extra.NWM_SquareFootageUnfinished}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Building Area Total :</span>
                    <span className="property-value">
                      {propertyData[0]?.area.extra.BuildingAreaTotal}
                    </span>
                  </p>
                  <br />
                  <p>
                    <span className="property-id">
                      Square Footage Finished :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.area.extra.NWM_SquareFootageFinished}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Basement</h1>
                  <p>
                    <span className="property-id">Info:</span>
                    <span className="property-value">
                      {propertyData[0]?.basement.info}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Bathroom</h1>
                  <p>
                    <span className="property-id">Total Count :</span>
                    <span className="property-value">
                      {propertyData[0]?.bathroom?.count}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Baths Full Upper :</span>
                    <span className="property-value">
                      {propertyData[0]?.bathroom.subcounts.NWM_BathsFullUpper}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      Baths Three Quarter Upper:
                    </span>
                    <span className="property-value">
                      {
                        propertyData[0]?.bathroom.subcounts
                          .NWM_BathsThreeQuarterUpper
                      }
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Bathrooms :</span>
                    <span className="property-value">
                      {propertyData[0]?.bathroom?.subcounts.NWM_Bathrooms}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Bedroom</h1>
                  <p>
                    <span className="property-id">Total Count :</span>
                    <span className="property-value">
                      {propertyData[0]?.bedroom?.count}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Bedrooms Upper :</span>
                    <span className="property-value">
                      {propertyData[0]?.bedroom?.subcounts.NWM_BedroomsUpper}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Dates</h1>
                  <p>
                    <span className="property-id">
                      Photos Change Timestamp :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.dates.extra.PhotosChangeTimestamp}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      Estimated Completion Date :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.dates.extra.NWM_OffersReviewDate}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      Contract Status Change Date :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.dates.extra.ContractStatusChangeDate}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Tax Year :</span>
                    <span className="property-value">
                      {propertyData[0]?.dates.extra.TaxYear}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      Originating System Modification Timestamp :
                    </span>
                    <span className="property-value">
                      {
                        propertyData[0]?.dates.extra
                          .OriginatingSystemModificationTimestamp
                      }
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Flooring</h1>
                  <p>
                    <span className="property-id">Flooring :</span>
                    <span className="property-value">
                      {propertyData[0]?.flooring.flooring}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Garage</h1>
                  <p>
                    <span className="property-id">Closed :</span>
                    <span className="property-value">
                      {propertyData[0]?.garage.closed}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Attached :</span>
                    <span className="property-value">
                      {propertyData[0]?.garage.attached}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Count :</span>
                    <span className="property-value">
                      {propertyData[0]?.garage.count}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Garage Y/N :</span>
                    <span className="property-value">
                      {propertyData[0]?.garage.extra.GarageYN}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">HOA</h1>
                  <p>
                    <span className="property-id">Fees :</span>
                    <span className="property-value">
                      {propertyData[0]?.hoa?.fees || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Per month :</span>
                    <span className="property-value">
                      {propertyData[0]?.hoa?.per_month || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Association Y/N :</span>
                    <span className="property-value">
                      {propertyData[0]?.hoa?.extra.AssociationYN || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Listing Agent</h1>
                  <p>
                    <span className="property-id">Id :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.id || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">First Name :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.fname || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Office Name :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.o_name || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Office MLS ID :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.o_mls_id || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Company Name :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.listing_company_name ||
                        "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Phone Number :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.phone_number || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">List Office Key:</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.listing_company_details
                        .ListOfficeKey || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Listing Key Numeric :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.listing_company_details
                        .ListingKeyNumeric || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Listing Contract Date :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.listing_company_details
                        .ListingContractDate || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Listing Terms :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.listing_company_details
                        .ListingTerms || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">List Agent Key :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.listing_company_details
                        .ListAgentKey || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      List Agent Key Numeric :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.listing_company_details
                        .ListAgentKeyNumeric || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">
                      Originating System Name :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.listing_company_details
                        .OriginatingSystemName || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">List Office Phone :</span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.listing_company_details
                        .ListOfficePhone || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      List Office Key Numeric :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.listing_agent?.listing_company_details
                        .ListOfficeKeyNumeric || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Miscellaneous</h1>
                  <p>
                    <span className="property-id"> Number Of Showers :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_NumberOfShowers || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Water Heater Type :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_WaterHeaterType || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Power Production Type :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.PowerProductionType || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">
                      {" "}
                      Internet Address Display Y/N :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.InternetAddressDisplayYN || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Seller Disclosure :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_SellerDisclosure || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">
                      {" "}
                      First Right Of Refusal :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_FirstRightOfRefusal || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id"> Show Map Link :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_ShowMapLink || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Water Heater Location :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_WaterHeaterLocation || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      {" "}
                      M L S Square Footage Source :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_MLSSquareFootageSource ||
                        "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id"> Mls Status :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.MlsStatus || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Topography :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.Topography || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Style Code :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_StyleCode || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id"> Photos Count :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.PhotosCount || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Community Features :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.CommunityFeatures || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">New Construction Y/N :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NewConstructionYN || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Listing Key :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.ListingKey || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">DPR Y/N:</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_DPRYN || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Senior Community Y/N :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.SeniorCommunityYN || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Cooling :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.Cooling || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Water Access :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_WaterAccess || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Senior Exemption:</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_SeniorExemption || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Mlg Can Use :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.MlgCanUse || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id"> Title Company :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_TitleCompany || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      Internet Entire Listing Display Y/N:
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.InternetEntireListingDisplayYN ||
                        "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Covered Spaces :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.CoveredSpaces || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id"> F I R P T A Y N :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_FIRPTAYN || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      {" "}
                      Internet Automated Valuation Display Y N :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.misc
                        ?.InternetAutomatedValuationDisplayYN || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id"> Vegetation :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.Vegetation || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">
                      {" "}
                      Irrigation Water Rights Y N :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.IrrigationWaterRightsYN || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Country :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.Country || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id"> Carport Y N :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.CarportYN || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id"> Building Information :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_BuildingInformation || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id"> Direction Faces :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.DirectionFace || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id"> Building Name :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.BuildingName || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id"> Source System Name :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.SourceSystemName || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Lot Features:</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.LotFeatures || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Short Term Rental Y/N :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_ShortTermRentalYN || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">
                      {" "}
                      Preliminary Title Ordered :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_PreliminaryTitleOrdered ||
                        "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Energy Source :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_EnergySource || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Sewer Company:</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_SewerCompany || "N/A"}
                    </span>
                  </p>{" "}
                  <p>
                    <span className="property-id">Roof :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.Roof || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Entry Location :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.EntryLocation || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Third Party Approval :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_ThirdPartyApproval || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Offers :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_Offers || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Power Company :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_PowerCompany || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Parcel Number :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.ParcelNumber || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Living Area :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.LivingArea || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      Cumulative Days On Market :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.CumulativeDaysOnMarket || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id"> Sale Type :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_SaleType || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Foundation Details :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.FoundationDetails || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">
                      Internet Consumer Comment Y/N :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.InternetConsumerCommentYN ||
                        "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Sewer :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.Sewer || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Site Features :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_SiteFeatures || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Heating :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.Heating || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Possession :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.Possession || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id"> M L S Area Major :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.MLSAreaMajor || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id"> Structure Type :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.StructureType || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Number Of Bathtubs :</span>
                    <span className="property-value">
                      {propertyData[0]?.misc?.NWM_NumberOfBathtubs || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Parking</h1>
                  <p>
                    <span className="property-id">Car :</span>
                    <span className="property-value">
                      {propertyData[0]?.parking?.car_count || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Parking Features :</span>
                    <span className="property-value">
                      {propertyData[0]?.parking?.extra?.ParkingFeatures ||
                        "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Open Parking Y/N :</span>
                    <span className="property-value">
                      {propertyData[0]?.parking?.extra?.OpenParkingYN || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Pool</h1>
                  <p>
                    <span className="property-id">Info :</span>
                    <span className="property-value">
                      {propertyData[0]?.pool?.info || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Pool Features :</span>
                    <span className="property-value">
                      {propertyData[0]?.pool?.extra?.PoolFeatures || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Price</h1>
                  <p>
                    <span className="property-id">Current Price :</span>
                    <span className="property-value">
                      {propertyData[0]?.price?.current || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Start Price :</span>
                    <span className="property-value">
                      {propertyData[0]?.price?.start || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Tax Annual Amount :</span>
                    <span className="property-value">
                      {propertyData[0]?.price?.extra?.TaxAnnualAmount || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Property Type</h1>
                  <p>
                    <span className="property-id">Type :</span>
                    <span className="property-value">
                      {propertyData[0]?.property_type?.value || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Info:</span>
                    <span className="property-value">
                      {propertyData[0]?.property_type?.info || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Selling agent</h1>
                  <p>
                    <span className="property-id">
                      Buyer Agency Compensation :
                    </span>
                    <span className="property-value">
                      {propertyData[0]?.sold_info?.listing_company_details
                        ?.BuyerAgencyCompensation || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Short sale</h1>
                  <p>
                    <span className="property-id">Info :</span>
                    <span className="property-value">
                      {propertyData[0]?.short_sale?.info || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Status</h1>
                  <p>
                    <span className="property-id">Has heating :</span>
                    <span className="property-value">
                      {propertyData[0]?.status?.value || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Info :</span>
                    <span className="property-value">
                      {propertyData[0]?.status?.info || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Utilities</h1>
                  <p>
                    <span className="property-id">Elevation Units :</span>
                    <span className="property-value">
                      {propertyData[0]?.utilities?.extra?.ElevationUnits ||
                        "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">View</h1>
                  <p>
                    <span className="property-id">Has View :</span>
                    <span className="property-value">
                      {propertyData[0]?.has_view?.value || "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Info :</span>
                    <span className="property-value">
                      {propertyData[0]?.has_view?.info || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Water front</h1>
                  <p>
                    <span className="property-id">Water Company :</span>
                    <span className="property-value">
                      {propertyData[0]?.water_fromt?.extra.NWM_WaterCompany ||
                        "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="property-id">Water Source :</span>
                    <span className="property-value">
                      {propertyData[0]?.water_fromt?.extra.WaterSource || "N/A"}
                    </span>
                  </p>
                  <br />
                  <h1 className="more-details">Water Source</h1>
                  <p>
                    <span className="property-id">Has Water Source :</span>
                    <span className="property-value">
                      {propertyData[0]?.water_source?.value || "N/A"}
                    </span>
                  </p>
                </div>
              </TabPanel>
            </Tabs>
            <p class="description">
              * Listing provided courtesy of
              <img style={{ width: "5rem" }} src={MLS} alt="Image" /> as
              distributed by MLSGRID.<br></br>
              Based on information submitted to the MLS GRID as of Tue Jan 20
              1970 18:15:08 GMT+0530 (India Standard Time) .All data is obtained
              from various sources and may not have been verified by broker or
              MLS GRID. Supplied Open House Information is subject to change
              without notice. All information should be independently reviewed
              and verified for accuracy. Properties may or may not be listed by
              the office/agent presenting the information.
              <br></br>
              The information contained in this listing has not been verified by
              ShopProp or the MLS and should be verified by the buyer.
            </p>
            <div>
              <p class="terms-conditions">
                {" "}
                The Digital Millennium Copyright Act of 1998, 17 U.S.C. § 512
                (the “DMCA”) provides recourse for copyright owners who believe
                that material appearing on the Internet infringes their rights
                under U.S. copyright law. If you believe in good faith that any
                content or material made available in connection with our
                website or services infringes your copyright, you (or your
                agent) may send us a notice requesting that the content or
                material be removed, or access to it blocked. Notices must be
                sent in writing by email to DMCAnotice@MLSGrid.com. <br></br>
                The DMCA requires that your notice of alleged copyright
                infringement include the following information: <br></br>
                (1) description of the copyrighted work that is the subject of
                claimed infringement; <br></br>
                (2) description of the alleged infringing content and
                information sufficient to permit us to locate the content;{" "}
                <br></br>
                (3) contact information for you, including your address,
                telephone number and email address; <br></br>
                (4) a statement by you that you have a good faith belief that
                the content in the manner complained of is not authorized by the
                copyright owner, or its agent, or by the operation of any law;{" "}
                <br></br>
                (5) a statement by you, signed under penalty of perjury, that
                the information in the notification is accurate and that you
                have the authority to enforce the copyrights that are claimed to
                be infringed; and <br></br>
                (6) a physical or electronic signature of the copyright owner or
                a person authorized to act on the copyright owner’s behalf.
                Failure to include all of the above information may result in
                the delay of the processing of your complaint.{" "}
              </p>
            </div>
            <div className="border">
              <div className="highlights-div">
                <h1 class="highlights">Highlights</h1>
                <p className="property-detail">
                  <span className="property-id">Type</span>
                  <span className="property-value">
                    {propertyData[0]?.property_type?.class}
                  </span>
                </p>

                <p className="property-detail">
                  <span className="property-id">Year Built</span>
                  <span className="property-value">
                    {propertyData[0]?.year_built}
                  </span>
                </p>

                <p className="property-detail">
                  <span className="property-id">Lot Size</span>
                  <span className="property-value">
                    {propertyData[0]?.area?.lot} Sq.Ft
                  </span>
                </p>

                <p className="property-detail">
                  <span className="property-id">Home Size</span>
                  <span className="property-value">
                    {propertyData[0]?.area?.finished} Sq.Ft
                  </span>
                </p>

                <p className="property-detail">
                  <span className="property-id">Bedrooms</span>
                  <span className="property-value">
                    {propertyData[0]?.bedroom?.count}
                  </span>
                </p>

                <p className="property-detail">
                  <span className="property-id">Bathrooms</span>
                  <span className="property-value">
                    {propertyData[0]?.bathroom?.count}
                  </span>
                </p>

                <p className="property-detail">
                  <span className="property-id">HOA</span>
                  <span className="property-value">
                    ${propertyData[0]?.hoa?.per_month}/month
                  </span>
                </p>
                <p>
                  Powered by{" "}
                  <img style={{ width: "5rem" }} src={MLS} alt="Image" />
                </p>
              </div>
            </div>
            <div className="border">
              <div className="highlights-div">
                <form
                  onSubmit={contactFunction}
                  style={{
                    marginTop: "20px",
                    width: "98%",
                    marginBottom: "55px",
                  }}
                >
                  <h2 style={{ textAlign: "center", color: "#333D79FF" }}>
                    Drop Message Here
                  </h2>
                  <FormGroup>
                    <TextField
                      label="Username"
                      name="name"
                      value={contactForm.name}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      value={contactForm.email}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      label="Message"
                      multiline
                      rows={4}
                      value={contactForm.message}
                      onChange={handleChange}
                      name="message"
                      required
                    />
                  </FormGroup>
                  <div className="send-btn">
                    <button
                      className="w-75 mt-3 p-2 send-btn-2 btn"
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default PropertyDetails;
